import firebase                               from 'firebase/app'
import 'firebase/functions'
import React, { useEffect, useRef, useState } from 'react'
import { isMobile }                           from 'react-device-detect'
import styled                                 from 'styled-components'

const Contact = () => {
    const textareaRef           = useRef<HTMLTextAreaElement>(null)
    const [email, setEmail]     = useState<string>('')
    const [message, setMessage] = useState<string>('')
    const [success, setSuccess] = useState<boolean>(false)
    useEffect(() => {
        if (textareaRef && textareaRef.current) {
            const unsubscribe = textareaRef.current.oninput = () => {
                // @ts-ignore
                textareaRef.current.style.height = '5px'
                // @ts-ignore
                textareaRef.current.style.height = textareaRef.current.scrollHeight + 5 + 'px'
            }
            return () => unsubscribe()
        }
    }, [textareaRef])
    const sendMessage = () => {
        setSuccess(false)
        if (email && message) {
            const sendEmail = firebase.functions().httpsCallable('sendEmail')
            sendEmail({ email, message }).then(() => {
                setEmail('')
                setMessage('')
                setSuccess(true)
            })
        }
    }
    return (
        <StyledContact>
            <StyledSection>
                <form>
                    <StyledLabel htmlFor="">Your e-mail address</StyledLabel>
                    {/* @ts-ignore */}
                    <StyledInput type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    <StyledLabel htmlFor="">Write your message</StyledLabel>
                    <StyledTextarea ref={textareaRef} value={message} onChange={(e) => setMessage(e.target.value)}/>
                    <StyledSend onClick={() => sendMessage()}><span>SEND</span></StyledSend>
                    {(success && !email && !message) && <div style={{ color: 'green', textAlign: 'right', marginTop: 10 }}>Message sent.</div>}
                </form>
                <StyledLinksSection>
                    <a href={`mailto:info@theremont.com`}>INFO@THEREMONT.COM<br/>
                    </a>
                    <a href="tel:+447823554622">+44 7823 554 622</a>
                </StyledLinksSection>
            </StyledSection>
        </StyledContact>
    )
}

export default Contact

const StyledContact = styled.div`
    color: #fff;
    background-color: #000;
    width: 100%;
    height: 100vh;
`

const StyledSection = styled.section`
    max-width: 826px;
    margin: ${isMobile ? '80px 16px 0' : '150px auto 0'};
`

const StyledLabel = styled.label`
    font-size: 16px;
    font-weight: 800;
`

const StyledInput = styled.input`
    font-size: 16px;
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    color: #fff;
    margin: 30px 0;
    border-bottom: 2px solid;
    font-family: 'Gilroy', sans-serif;
    padding-bottom: 5px;
    border-radius: 0;
`

const StyledTextarea = styled.textarea`
    font-size: 16px;
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    color: #fff;
    margin: 30px 0;
    border-bottom: 2px solid;
    resize: none;
    height: 27px;
    font-family: 'Gilroy', sans-serif;
    padding-bottom: 3px;
    border-radius: 0;
`

const StyledLinksSection = styled.div`
    margin-top: ${isMobile ? '50px' : '100px'};
    a {
        color: #fff;
        text-decoration: none;
        font-size: ${isMobile ? '24px' : '64px'};
        font-weight: 800;
        word-wrap: break-word;
        &:hover {
            text-decoration: underline;
        }
    }
`

const StyledSend = styled.div`
   font-size: 24px;
   font-weight: 800;
   text-align: right;
   & > span {
      cursor: pointer;
   }
   & > span:hover {
      border-bottom: 2px solid;
   }
`
