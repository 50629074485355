import React, { useLayoutEffect, useState, Suspense } from 'react'
import Loader                                         from './Loader'
import Consultants                                    from './../screens/Consultants'
import Navigation                                     from './Navigation'
import Projects                                       from './../screens/Projects'
import Contact                                        from './../screens/Contact'
import styled                                         from 'styled-components'
import About                                          from './../screens/About'
import {
    Switch,
    Route, useLocation,
}                                                     from 'react-router-dom'
import { isMobile, browserName }            from 'react-device-detect'

const Home = React.lazy(() => import('./../screens/Home'))

const AppContainer: React.FC = () => {
    const [showGif, setShowGif]     = useState<boolean>(!isMobile)
    const [showPage, setShowPage]   = useState<boolean>(isMobile)
    const [currentBg, setCurrentBg] = useState<number>(0)
    const [gifLoaded, setGifLoaded] = useState<boolean>(false)
    useLayoutEffect(() => {
        if (gifLoaded) {
            const isFirefox = browserName === 'Firefox'
            const time      = isFirefox ? 5500 : 5500
            // const storageGifShowed = localStorage.getItem('animationShowed') === 'true'
            // if (storageGifShowed) {
            //     setShowGif(false)
            // } else {
            //     localStorage.setItem('animationShowed', 'true')
            // }
            setTimeout(() => {
                setShowGif(false)
            }, time)
            setTimeout(() => {
                setShowPage(true)
            }, time - 1000)
        }
    }, [gifLoaded])
    const { pathname } = useLocation()
    const textWhite    = pathname === '/about' || pathname === '/contact' || (pathname === '/' && currentBg % 2 === 0)
    return (
        <>
            {(showPage || pathname !== '/') && <Navigation textWhite={textWhite}/>}
            <StyledAppContainer>
                <Switch>
                    <Route exact={true} path="/"><Suspense fallback={<Loader/>}><Home showGif={showGif} showPage={showPage}
                                                                                   setCurrentBg={(bg: number) => setCurrentBg(bg)}
                                                                                   currentBg={currentBg} setGifLoaded={() => setGifLoaded(true)}/>
                    </Suspense></Route>
                    <Route path="/about"><About/></Route>
                    <Route path="/contact"><Contact/></Route>
                    <Route path="/projects"><Projects/></Route>
                    <Route path="/consultants"><Consultants/></Route>
                </Switch>
            </StyledAppContainer>
        </>
    )
}

export default AppContainer

const StyledAppContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`
