export const urlDesktop = 'https://res.cloudinary.com/rfv5ujm/image/upload/q_auto/'
export const urlMobile = 'https://res.cloudinary.com/rfv5ujm/image/upload/q_auto:low/'

export const stokeNewington = [
    'v1583171401/stoke%20newington/stoke_newington_1_xllo4m.jpg',
    'v1583171403/stoke%20newington/stoke_newington_2_bplipi.jpg',
    'v1583171402/stoke%20newington/stoke_newington_3_sp6hve.jpg',
    'v1583341744/stoke%20newington/4_k5s7cf.jpg',
    'v1583171402/stoke%20newington/stoke_newington_5_jq4ut3.jpg',
    'v1583171402/stoke%20newington/stoke_newington_6_erw0rl.jpg',
    'v1583341744/stoke%20newington/7_ffvf4x.jpg',
    'v1583945216/stoke%20newington/8_o7baza.jpg',
    'v1583171401/stoke%20newington/stoke_newington_11_pav8wa.jpg',
]

export const stokeNewingtonMobile = [
    'v1583171401/stoke%20newington/stoke_newington_1_xllo4m.jpg',
    '',
    'v1583191109/stoke%20newington/2mob_mcgtw1.jpg',
    'v1583171402/stoke%20newington/stoke_newington_3_sp6hve.jpg',
    'v1583191160/stoke%20newington/4amob_crkoyh.jpg',
    'v1583191161/stoke%20newington/4mob_gnwsyd.jpg',
    'v1583171402/stoke%20newington/stoke_newington_5_jq4ut3.jpg',
    'v1583171402/stoke%20newington/stoke_newington_6_erw0rl.jpg',
    'v1583191187/stoke%20newington/7amob_dxx4wz.jpg',
    'v1583191282/stoke%20newington/7mob_rihhos.jpg',
    'v1583945216/stoke%20newington/8_o7baza.jpg',
    'v1583171401/stoke%20newington/stoke_newington_11_pav8wa.jpg',
]

export const islington = [
    'v1583171314/islington/islington_1_hyw7u9.jpg',
    'v1583341835/islington/2_sublua.jpg',
    'v1583341859/islington/3_vmsgea.jpg',
    'v1583171314/islington/islington_4_hz0nx0.jpg',
    'v1583171314/islington/islington_5_glvnmc.jpg',
    'v1583171314/islington/islington_6_dbj1z4.jpg',
    'v1583171314/islington/islington_7_frssjb.jpg',
]

export const islingtonMobile = [
    'v1583171314/islington/islington_1_hyw7u9.jpg',
    '',
    'v1583191402/islington/2mob_wkxbat.jpg',
    'v1583191402/islington/3amob_vpwhml.jpg',
    'v1583191402/islington/3mob_zhtgfl.jpg',
    'v1583191402/islington/4mob_vsfiky.jpg',
    'v1583191402/islington/4amob_vhemfr.jpg',
    'v1583171314/islington/islington_5_glvnmc.jpg',
    'v1583191402/islington/6mob_nw2wye.jpg',
    'v1583191402/islington/6amob_f5ymdr.jpg',
    'v1583171314/islington/islington_7_frssjb.jpg',
]

export const dalston = [
    'v1583341991/dalston/1_ezebdu.jpg',
    'v1583341991/dalston/2_ztp6ai.jpg',
    'v1583341991/dalston/3_ecvaqb.jpg',
    'v1583341991/dalston/4_qutfxe.jpg',
    'v1583341991/dalston/5_gexe97.jpg',
    'v1583341991/dalston/6_jpgiwh.jpg',
    'v1583341991/dalston/7_ixrg9g.jpg',
    'v1583341991/dalston/8_tutnt0.jpg',
]

export const dalstonMobile = [
    'v1583341991/dalston/1_ezebdu.jpg',
    '',
    'v1583191585/dalston/3mob_sgsy2f.jpg',
    'v1583341991/dalston/3_ecvaqb.jpg',
    'v1583341991/dalston/4_qutfxe.jpg',
    'v1583191585/dalston/4mob_acls9v.jpg',
    'v1583191585/dalston/5mob_atskjx.jpg',
    'v1583341991/dalston/6_jpgiwh.jpg',
    'v1583191585/dalston/6amob_fyrytb.jpg',
    'v1583191586/dalston/6mob_dqodbz.jpg',
    'v1583341991/dalston/8_tutnt0.jpg',
]

export const barnes = [
    'v1583171048/barens/barens_1_muflio.jpg',
    'v1583171048/barens/barens_3a_gdlrtu.jpg',
    'v1583171048/barens/barens_3_qmnk7t.jpg',
    'v1583341576/barens/4_d9neqg.jpg',
    'v1583171048/barens/barens_5_h9brcn.jpg',
    'v1583171049/barens/barens_7_inneyj.jpg',
    'v1583171049/barens/barens_8_zuovc8.jpg',
]

export const barnesMobile = [
    'v1583171048/barens/barens_1_muflio.jpg',
    '',
    'v1583191787/barens/3amob_se5g6w.jpg',
    'v1583171048/barens/barens_3_qmnk7t.jpg',
    'v1583341576/barens/4_d9neqg.jpg',
    'v1583171048/barens/barens_5_h9brcn.jpg',
    'v1583171049/barens/barens_7_inneyj.jpg',
    'v1583191787/barens/8mob_gaqdel.jpg',
    'v1583191787/barens/8amob_hhqlyh.jpg',
]

export const chelsea = [
    'v1583171232/chelsea/chelsea_1_vopnbh.jpg',
    'v1583171232/chelsea/chelsea_2_iypthv.jpg',
    'v1583171232/chelsea/chelsea_4_c9dnxu.jpg',
    'v1583171232/chelsea/chelsea_5_mxgfkj.jpg',
    'v1583171232/chelsea/chelsea_6_y9ydfc.jpg',
    'v1583171233/chelsea/chelsea_7_olfurw.jpg',
]

export const chelseaMobile = [
    'v1583171232/chelsea/chelsea_1_vopnbh.jpg',
    '',
    'v1583191970/chelsea/2mob_zeuca7.jpg',
    'v1583171232/chelsea/chelsea_4_c9dnxu.jpg',
    'v1583171232/chelsea/chelsea_5_mxgfkj.jpg',
    'v1583171232/chelsea/chelsea_6_y9ydfc.jpg',
    'v1583171233/chelsea/chelsea_7_olfurw.jpg',
]