import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { isMobile }                                            from 'react-device-detect'
import { Carousel }                                            from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './Projects.css'
import { Transition }                                          from 'react-transition-group'
import { useDrawerAnimation }                                  from '../_utils/useDrawerAnimation'
import {
    barnes, barnesMobile, chelsea, chelseaMobile, dalston, dalstonMobile, islington, islingtonMobile, stokeNewington, stokeNewingtonMobile, urlDesktop,
    urlMobile,
}                                                              from '../_utils/images'
import styled                                                  from 'styled-components'
// @ts-ignore
import baguetteBox                                             from 'baguettebox.js'
import 'baguettebox.js/dist/baguetteBox.min.css'
import { useLocation }                                from 'react-router-dom'

const Projects = () => {
    const { duration, defaultStyle, transitionStyles } = useDrawerAnimation()
    const [descriptionOpened, setDescriptionOpened]    = useState<number>(-1)

    const [projectNumber, setProjectNumber]         = useState<number>(1)
    const [slideNumber, setSlideNumber]             = useState<number>(0)
    const [mobileSlideNumber, setMobileSlideNumber] = useState<number[]>([0, 0, 0, 0, 0])
    const { pathname }                              = useLocation()

    useEffect(() => {
        const lastChar = pathname.length
        setProjectNumber(Number(pathname.charAt(lastChar - 1)) || 1)
        setSlideNumber(0)
    }, [pathname])

    const projects       = [stokeNewington, islington, dalston, barnes, chelsea]
    const projectsMobile = [
        { desc: 'A refurbishment and extension of the... (continue reading)', title: 'Stoke Newington', data: stokeNewingtonMobile },
        { desc: 'Work to this house included rear... (continue reading)', title: 'Islington', data: islingtonMobile },
        { desc: 'A refurbishment, extension and basement... (continue reading)', title: 'Dalston', data: dalstonMobile },
        { desc: 'Refurbishment of two bedroom flat in... (continue reading)', title: 'Barnes', data: barnesMobile },
        { desc: 'This bachelor pad in one of Chelsea... (continue reading)', title: 'Chelsea', data: chelseaMobile },
    ]
    const projectRefs    = useRef<any[keyof number][]>([[], [], [], [], []])

    const handleChangeSlide = (slide: number): number => {
        setSlideNumber(slide)
        return slide + 1
    }

    const handleChangeMobileSlide = (slide: number, project: number) => {
        const array    = mobileSlideNumber
        array[project] = slide
        setMobileSlideNumber(array)
        return slide
    }
    useLayoutEffect(() => {
        if (isMobile) {
            baguetteBox.run('.gallery0')
            baguetteBox.run('.gallery1')
            baguetteBox.run('.gallery2')
            baguetteBox.run('.gallery3')
            baguetteBox.run('.gallery4')
        }
    }, [projectNumber])

    const descriptions = [
        <StyledDescription>
            <b>Stoke Newington</b><br/><br/>
            A refurbishment and extension of the ground and first floor to a house in N16. Scope of work for Phase 1 consisted of bespoke fitted furniture,
            polished concrete flooring and exposed brickwork to both interior and exterior and custom made Glulam pergola. In Phase 2 we replaced the existing
            butterfly roof with a new lead-clad mansard and as a result the Client received a spacious Master Suite with roof terrace to front.',
        </StyledDescription>,
        <StyledDescription>
            <b>Islington</b><br/><br/>
            Work to this house included rear extension and side extension with double height ceiling. Both roofs are lit by custom made rectangular and
            trapezoid triple glazed skylights.
        </StyledDescription>,
        <StyledDescription>
            <b>Dalston</b><br/><br/>
            A refurbishment, extension and basement conversion of a Victorian town house to provide a modern kitchen with industrial chic and a glamorous
            underground playroom.
        </StyledDescription>,
        <StyledDescription>
            <b>Barnes</b><br/><br/>
            Refurbishment of two bedroom flat in the heart of Barnes Village. Client's aim was to modernize their home and retain as many original features as
            possible. Old kitchen furniture received a specialist joinery treatment.
        </StyledDescription>,
        <StyledDescription>
            <b>Chelsea</b><br/><br/>
            This bachelor pad in one of Chelsea mansion blocks was subject to extensive works including custom made joinery and multi-room AV System.
        </StyledDescription>,
    ]

    if (isMobile) {
        return (
            <>
                {<Transition in={descriptionOpened !== -1} timeout={duration}>{(state: 'entering') => (<StyledDescriptionPopup style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                }}>
                    <StyledX onClick={() => setDescriptionOpened(-1)}>✕</StyledX>
                    {descriptions[descriptionOpened]}
                </StyledDescriptionPopup>)}</Transition>}
                <StyledMobileCarouselContainer className={'projects-carousel'}>
                    {projectsMobile.map((project, pi) => (<React.Fragment key={pi}>
                        <StyledMobileTitle>{project.title}</StyledMobileTitle>
                        <Carousel showArrows={true} showStatus={false} showIndicators={false} showThumbs={false} swipeable={true}
                                  onChange={(slide) => handleChangeMobileSlide(slide, pi)} selectedItem={mobileSlideNumber[pi]} className={`gallery${pi}`}>
                            {projectsMobile[pi].data.map((src, i) => {
                                                             return (
                                                                 (i === 1) ? <StyledProjectDesc onClick={() => setDescriptionOpened(pi)}>{project.desc}</StyledProjectDesc> :
                                                                 <a target='_blank' key={`img${i}`}
                                                                    href={urlDesktop + src}>
                                                                     <div style={{
                                                                         width:              '100vw',
                                                                         height:             'calc(100vh / 3)',
                                                                         backgroundImage:    `url(${urlMobile + src})`,
                                                                         backgroundSize:     'cover',
                                                                         backgroundPosition: 'center center',
                                                                     }}/>
                                                                 </a>
                                                             )
                                                         },
                            )}
                        </Carousel>
                    </React.Fragment>))}
                </StyledMobileCarouselContainer>
            </>
        )
    }

    return (
        <StyledCarouselContainer className={'projects-carousel'}>
            <Carousel showArrows={true} showStatus={false} showIndicators={false} showThumbs={false} transitionTime={500} swipeable={false}
                      onChange={(s) => handleChangeSlide(s)} selectedItem={slideNumber}>
                {projects[projectNumber - 1].map((src, i) => (
                    <StyledRelativeContainer style={{ position: 'relative' }}>
                        {(i === 1) && descriptions[projectNumber - 1]}
                        <img src={urlDesktop + src}
                             style={{ width: 'calc(100vw - 270px)', height: 'calc(100vh - 148px)' }}
                             ref={(ref) => projectRefs.current[projectNumber - 1][i] = ref}/>
                    </StyledRelativeContainer>),
                )}
            </Carousel>
        </StyledCarouselContainer>
    )
}

export default Projects

const StyledCarouselContainer = styled.div`
    width: calc(100% - 270px);
    margin-left: 270px;
    margin-top: 148px;
`

const StyledDescription = styled.div`
    font-size: 16px;
    position: absolute;
    top: ${isMobile ? '50px' : '40%'};
    left: ${isMobile ? '16px' : '60px'};
    width: ${isMobile ? 'calc(100% - 32px)' : '43%'};
    text-align: left;
`

const StyledRelativeContainer = styled.div`
    position: relative;
`

const StyledMobileCarouselContainer = styled.div`
    margin: 60px 0;
    width: 100%;
`

const StyledMobileTitle = styled.h5`
    font-weight: 800;
    font-size: 16px;
    margin: 30px 16px;
`

const StyledProjectDesc = styled.div`
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh / 3);
    padding: 0 35px;
    cursor: pointer;
`

const StyledDescriptionPopup = styled.div`
   width: 100vw;
   height: 100vh;
   position: fixed;
   top: 0;
   left: 0;
   background: white;
   z-index: 10;
   display: flex;
   flex-direction: column;
`

const StyledX = styled.div`
  font-weight: 800;
  align-self: flex-end;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  line-height: 36px;
  font-size: 36px;
`