export const useDrawerAnimation = () => {
    const duration = 200

    const defaultStyle = {
        transition: `all ${duration}ms ease-in-out`,
        left:       '-100vw',
    }

    const transitionStyles = {
        entering: { left: '-100vw' },
        entered:  { left: 0 },
        exiting:  { left: 0 },
        exited:   { left: '-100vw' },
    }

    return { defaultStyle, duration, transitionStyles }
}
