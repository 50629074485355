import firebase              from 'firebase/app'
import 'firebase/auth'
import React                 from 'react'
import './App.css'
import { createGlobalStyle } from 'styled-components'
import AppContainer          from './components/AppContainer'
import {
    BrowserRouter as Router,
}                            from 'react-router-dom'
// @ts-ignore
import GilroyLight           from './assets/Gilroy-Light.otf'
// @ts-ignore
import GilroyExtraBold       from './assets/Gilroy-ExtraBold.otf'

const firebaseConfig = {
    apiKey:            'AIzaSyBxF4mAqkKh7HZu88BF3gt9Dv5sPP2KqIc',
    authDomain:        'the-remont.firebaseapp.com',
    databaseURL:       'https://the-remont.firebaseio.com',
    projectId:         'the-remont',
    storageBucket:     'the-remont.appspot.com',
    messagingSenderId: '462102925038',
    appId:             '1:462102925038:web:674af1c63f693232e32dff',
    measurementId:     'G-7F5RD95GS8',
}

firebase.initializeApp(firebaseConfig)

const App: React.FC = () => {
    return (
        <Router>
            <GlobalStyle/>
            <div className="App">
                <AppContainer/>
            </div>
        </Router>
    )
}

export default App

const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'Gilroy';
      src: url(${GilroyExtraBold}) format('opentype');
      font-weight: 800;
      font-style: normal;
  }
  @font-face {
      font-family: 'Gilroy';
      src: url(${GilroyLight}) format('opentype');
      font-weight: 300;
      font-style: normal;
  }
  body {
    font-family: 'Gilroy', sans-serif;
    margin: 0;
  }
`