import React                 from 'react'
import { isMobile }          from 'react-device-detect'
import styled, { keyframes } from 'styled-components'
import logo                  from './../assets/logoBig.svg'

const About = () => {
    return (
        <StyledAbout>
            <StyledSection>
                <p>REMONT are a small but skilled team of builders that specialises in high specification refurbishments, conversions and extensions, with a proven track record in these fields. M&E contractors, glazing specialists and furniture makers support our crew; all of which have worked with us for many years.<br/><br/>
                   Remont undertake all forms of building work in the residential sector.<br/><br/>
                   We understand the complexity and challenges of modern design, enjoy working with new materials and take an active part in incorporating design development where necessary.<br/><br/>
                   We have great passion for what we do and we pride ourselves on our honest, reliable approach, work standards and ethos.<br/><br/>
                   Every project we undertake receives our unique treatment. Delivering the highest quality finished product and client satisfaction is our main goal.
                </p>
            </StyledSection>
            {!isMobile && <StyledLogoContainer>
                <StyledLogo/>
            </StyledLogoContainer>}
        </StyledAbout>
    )
}

export default About

const StyledAbout = styled.div`
    color: #fff;
    background-color: #000;
    width: 100%;
    min-height: 100vh;
`

const StyledSection = styled.section`
    max-width: 750px;
    margin: ${isMobile ? '80px 16px 0' : '150px auto 0'};
    font-size: ${isMobile ? '14px' : '16px'};
`

const slide = keyframes`
  0% {
  transform: translate3d(0, 0, 0)
}
  100% {
  transform: translate3d(-1710px, 0, 0)
}
`


const StyledLogo = styled.div`
    width: calc(1710px * 3);
    height: 281px;
    background: url("${logo}") repeat-x;
    background-size: initial;
    animation: ${slide} 20s linear infinite;
`

const StyledLogoContainer = styled.div`
  position: absolute;
  bottom: 30px;
  overflow: hidden;
  height: 281px;
  width: 100vw;
`
