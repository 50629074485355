import React  from 'react'
import styled from 'styled-components'

interface ILogoSmallProps {
    white?: boolean
}

const LogoSmall = ({ white }: ILogoSmallProps) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='138'
            height='27'
            fill='none'
            viewBox='0 0 138 27'
            // style={white ? { filter: 'drop-shadow( 1px 1px 1px rgba(0, 0, 0, 1))' } : {}}
        >
            <StyledPath
                fill={white ? '#fff' : '#000'}
                d='M8.302 17.437H5.63v8.092H0V.902h9.85a8.423 8.423 0 018.444 8.444c0 3.025-1.83 5.769-4.538 7.142l5.241 9.041h-6.05l-4.645-8.092zM5.63 12.512H9.85c1.548 0 2.814-1.372 2.814-3.167 0-1.794-1.266-3.166-2.814-3.166H5.629v6.333zM37.153 20.11v5.418H21.56V.9h15.417V6.32H27.19V10.4h8.909v5.348h-8.91v4.362h9.965zM64.946 25.528h-5.628V11.209L52.95 21.658h-.634L45.95 11.209v14.319h-5.63V.9h5.63l6.684 10.942L59.317.9h5.629v24.627zM116.133.901v24.627h-4.222l-9.499-13.369v13.369h-5.628V.9h4.221l9.499 13.369V.901h5.629zM137.154 6.32h-6.446v19.208h-5.629V6.319h-6.446V.901h18.521V6.32zM80.842 0c-7.224 0-13.081 5.856-13.081 13.081 0 7.224 5.856 13.082 13.081 13.082 7.224 0 13.081-5.857 13.081-13.082C93.923 5.856 88.067 0 80.842 0zm0 20.64c-4.136 0-7.402-3.01-7.402-7.546 0-4.535 3.265-7.546 7.402-7.546 4.136 0 7.402 3.011 7.402 7.546 0 4.536-3.266 7.547-7.402 7.547z'
            />
        </svg>
    )
}

export default LogoSmall

const StyledPath = styled.path`
    transition: all 0.7s ease-in-out;
`