import React           from 'react'
import { isMobile }    from 'react-device-detect'
import styled          from 'styled-components'
import clarkeBanks     from './../assets/consultants/clarke-banks.jpg'
import markLewisDesign from './../assets/consultants/mark-lewis-design.jpg'
import ape             from './../assets/consultants/ape.jpg'
import qed             from './../assets/consultants/qed.jpg'
import rbg             from './../assets/consultants/rbd.jpg'


const Consultants = () => {
    const logos = [
        { src: ape, title: 'APE Architecture & Design' },
        { src: clarkeBanks, title: 'Clarke Banks' },
        { src: markLewisDesign, title: 'Mark Lewis Interior Design' },
        { src: qed, title: 'QED Structures' },
        { src: rbg, title: 'RBD Architecture & Interiors' },
    ]
    return (
        <StyledContainer>
            {logos.map(logo => <StyledLogoContainer>
                <StyledLogoTitle>{logo.title}</StyledLogoTitle>
                <StyledLogo src={logo.src} alt={logo.title}/>
            </StyledLogoContainer>)}
        </StyledContainer>
    )
}

export default Consultants

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: ${isMobile ? '16px' : '0'};
  margin-top: ${isMobile ? '80px' : '110px'};
`

const StyledLogo = styled.img`
  width: ${isMobile ? '130px' : '150px'};
`

const StyledLogoContainer = styled.div`
  display: flex;
  height: ${isMobile ? '90px' : '150px'};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledLogoTitle = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
`
