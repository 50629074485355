import React, { useState }    from 'react'
import { isMobile }           from 'react-device-detect'
import { Transition }         from 'react-transition-group'
import { useDrawerAnimation } from '../_utils/useDrawerAnimation'
import LogoSmall              from './LogoSmall'
import styled                 from 'styled-components'
import {
    NavLink,
    useLocation,
}                             from 'react-router-dom'
import PerfectScrollbar       from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'

interface INavigationProps {
    textWhite: boolean
}

const Navigation = (props: INavigationProps) => {
    const { duration, defaultStyle, transitionStyles } = useDrawerAnimation()
    const { textWhite }                                = props
    const { pathname }                                 = useLocation()
    const [navOpened, setNavOpened]                    = useState<boolean>(false)
    if (isMobile) {
        return (
            <>
                <StyledNavigationMobile onClick={() => setNavOpened(!navOpened)}>
                    <StyledHamburger white={textWhite}/>
                    <StyledHamburger white={textWhite}/>
                    <StyledHamburger white={textWhite}/>
                </StyledNavigationMobile>
                <StyledNavigationLogo>
                    <NavLink to='/'>
                        <LogoSmall white={textWhite}/>
                    </NavLink>
                </StyledNavigationLogo>
                {<Transition in={navOpened} timeout={duration}>{(state: 'entering') => (<StyledNavigationContent style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                }}>
                    <StyledX onClick={() => setNavOpened(false)}>✕</StyledX>
                    <StyledMobileNavItem onClick={() => setNavOpened(false)}><NavLink
                        className={pathname === '/about' ? 'active' : ''} to='/about'>About</NavLink></StyledMobileNavItem>
                    <StyledMobileNavItem onClick={() => setNavOpened(false)}><NavLink
                        className={pathname === '/contact' ? 'active' : ''} to='/contact'>Contact</NavLink></StyledMobileNavItem>
                    <StyledMobileNavItem onClick={() => setNavOpened(false)}><NavLink
                        className={pathname === '/projects' ? 'active' : ''} to='/projects'>Projects</NavLink></StyledMobileNavItem>
                    <StyledMobileNavItem onClick={() => setNavOpened(false)}><NavLink
                        className={pathname === '/consultants' ? 'active' : ''} to='/consultants'>Consultants</NavLink></StyledMobileNavItem>
                </StyledNavigationContent>)}</Transition>}
            </>
        )
    }
    return (
        <StyledNavigation>
            <StyledUl>
                <StyledNavItem white={textWhite}>
                    <NavLink to='/'>
                        <LogoSmall white={textWhite}/>
                    </NavLink>
                </StyledNavItem>
                <StyledNavItem white={textWhite}><NavLink to='/about'>About</NavLink></StyledNavItem>
                <StyledNavItem white={textWhite}><NavLink to='/contact'>Contact</NavLink></StyledNavItem>
                <StyledNavItem white={textWhite}><NavLink to='/projects' exact={false}>Projects</NavLink></StyledNavItem>
                {<StyledProjectsContainer opened={!isMobile && (pathname.includes('/projects'))}>
                    <PerfectScrollbar>
                        <StyledNavProjectItem white={textWhite}>
                            <NavLink to={'/projects'} exact={true}>Stoke Newington</NavLink>
                        </StyledNavProjectItem>
                        <StyledNavProjectItem white={textWhite}>
                            <NavLink to={'/projects/2'}>Islington</NavLink>
                        </StyledNavProjectItem>
                        <StyledNavProjectItem white={textWhite}>
                            <NavLink to={'/projects/3'}>Dalston</NavLink>
                        </StyledNavProjectItem>
                        <StyledNavProjectItem white={textWhite}>
                            <NavLink to={'/projects/4'}>Barnes</NavLink>
                        </StyledNavProjectItem>
                        <StyledNavProjectItem white={textWhite}>
                            <NavLink to={'/projects/5'}>Chelsea</NavLink>
                        </StyledNavProjectItem>
                    </PerfectScrollbar>
                </StyledProjectsContainer>}
                <StyledNavItem white={textWhite}><NavLink to='/consultants'>Consultants</NavLink></StyledNavItem>
            </StyledUl>

        </StyledNavigation>
    )
}

export default Navigation

const StyledNavigation = styled.div`
    z-index: 2;
    padding: 130px 0 147px 60px;
    min-width: 270px;
    max-height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
`

const StyledUl = styled.ul`
   padding: 0;
`

const StyledProjectsContainer = styled.div<{ opened: boolean }>`
    transition: all 0.3s;
    margin-bottom: ${props => props.opened ? '60px' : 0};
    height: ${props => props.opened ? '200px' : 0};
    overflow: hidden;
    transition-delay: 200ms;
`

const StyledNavItem = styled.li<{ white?: boolean }>`
    color: ${props => props.white ? '#fff' : '#000'};
    margin-bottom: 80px;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    list-style: none;
    max-width: 100%;
    transition: color 700ms ease-in-out;
    & > a.active {
      font-weight: 800;
    }
    & > a {
      color: inherit;
      text-decoration: none;
      // text-shadow: ${props => props.white ? '1px 1px 1px #000' : '0'};
    }
    &:hover > a:not(.active) {
      border-bottom: 2px solid ${props => props.white ? '#fff' : '#000'};
    }
`

const StyledNavProjectItem = styled(StyledNavItem)`
  font-size: 14px;
  margin-bottom: 20px;
`

const StyledNavigationMobile = styled.div`
  z-index: 5;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
`

const StyledNavigationLogo = styled.div`
  z-index: 5;
  position: absolute;
  top: 34px;
  left: 16px;
  display: inline-flex;
  align-items: center;
`

const StyledHamburger = styled.div<{ white?: boolean }>`
  height: 5px;
  margin-top: 5px;
  width: 100%;
  transition: background-color 700ms ease-in-out;
  background-color: ${props => props.white ? '#fff' : '#000'};
`

const StyledNavigationContent = styled.div`
   width: 100vw;
   height: 100vh;
   position: fixed;
   top: 0;
   left: 0;
   background: white;
   z-index: 10;
   display: flex;
   flex-direction: column;
`

const StyledMobileNavItem = styled.div`
  margin-top: 42px;
  font-weight: 800;
  font-size: 48px;
  cursor: pointer;
  display: inline-block;
  margin-left: 30px;
  margin-right: 30px;
  & > a.active {
    font-weight: 800;
    border-bottom: 5px solid #000;
  }
  & > a {
    color: inherit;
    text-decoration: none;
  }
`

const StyledX = styled.div`
  font-weight: 800;
  align-self: flex-end;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  line-height: 36px;
  font-size: 36px;
`
